import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lodash from "lodash";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import InsightsIcon from "@mui/icons-material/Insights";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import * as U2ReduxSlice from "../../Store/ReduxSlice";
import U2RoundedBox from "../../Components/Common/U2RoundedBox";
import U2MessageBox from "../../Components/Common/U2MessageBox";
import AnnotatorAPI from "../../API/AnnotatorAPI";
import U2ImageSetManagerLabelList from "./U2ImageSetManagerLabelList";
import U2ImageSetManagerLabelEditDialog from "./U2ImageSetManagerLabelEditDialog";
import U2ImageSetManagerSourceImageSetList from "./U2ImageSetManagerSourceImageSetList";
import U2ImageSetNameEditDialog from "./U2ImageSetNameEditDialog";
import U2ImageSetStatusEditDialog from "./U2ImageSetStatusEditDialog";
import SourceImageSetEntity from "../../API/SourceImageSetEntity";
// import DrawUtil from '../../Utils/DrawUtil';
import { SOURCESTATUS, ACLCODE } from "../../Enums/Enums";

import U2AuthComponent from "../../Components/Common/U2AuthComponent";
import U2AnnotationStatisticsDialog from "./U2AnnotationStatisticsDialog";
import U2ImageSetTagEditDialog from "./U2ImageSetTagEditDialog";

function ImageSetManager(props) {
  const [_projectId, setProjectId] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const _myInfo = useSelector(state => state.defaultReducer.myInfo);

  /**
   * 라벨 에디터 팝업 실행 여부
   * Boolean
   */
  const [_IslabelEditOpen, setIsLabelEditOpen] = useState(false);

  /**
   * 어노테이션 통계 팝업 실행 여부
   * Boolean
   */
  const [_isAnnotationStatisticsOpen, setIsAnnotationStatisticsOpen] =
    useState(false);

  /**
   * 라벨 목록 (@LibraryLabel)
   */
  const [_labels, setLabels] = useState([]);

  /**
   * 소스이미지셋 태그 라이브러리 (@GetSourceImageSetTag)
   */
  const [_sourceImageSetTag, setSourceImageSetTag] = useState([]);

  /**
   * 원시이미지세트 목록
   * {data : [{}], totalCount:Int}
   */
  const [_sourceImageSets, setSourceImageSets] = useState([]);

  /**
   * 원시이미지세트 목록 편집 가능 여부 (편집 / 삭제버튼 표출여부)
   */
  const [_isSourceImageSetListEditable, setIsSourceImageSetListEditable] =
    useState(false);

  /**
   * 메시지 박스 정보
   */
  const [_openMessageBox, setOpenMessageBox] = useState({
    open: false,
    title: "",
    description: "",
    okButtonCaption: "",
    cancelButtonCaption: "",
    onClose: null,
  });

  /**
   * 원시이미지세트 상태 변경 다이얼로그 정보
   */
  const [_imageSetStatusEditDialog, setImageSetStatusEditDialog] = useState({
    open: false,
    sourceImageSetId: -1,
  });

  /**
   * 원시이미지세트 태그 변경 다이얼로그 정보
   */
  const [_imageSetTagEditDialog, setImageSetTagEditDialog] = useState({
    open: false,
    sourceImageSetId: -1,
  });

  /**
   * 원시이미지세트 이름 변경 다이얼로그 정보
   */
  const [_imageSetNameEditDialog, setImageSetNameEditDialog] = useState({
    open: false,
    sourceImageSetId: -1,
    isEditMode: false,
  });

  /**
   * 원시이미지세트 이름 편집 텍스트 필드의 값
   */
  const [_sourceImageSetNameEditValue, setSourceImageSetNameEditValue] =
    useState("");

  /**
   * 원시이미지세트중 선택된 행의 ID
   */
  const [_selectedRows, setSelectedRows] = useState([]);

  /** 원시이미지세트 DataGrid 조회 조건 */
  const [_sourceImageSetQueryCondition, setSourceImageSetQueryCondition] =
    useState({
      Sort: {
        field: "sourceImageSetId",
        sort: "desc",
      },
      Filter: {
        field: "",
        value: "",
      },
      Page: {
        page: 0,
        pageSize: 25,
      },
    });

  /****************************************************************************************************
   *
   * Data Load
   *
   ****************************************************************************************************/
  const fctLoadSourceImageSetList = useCallback(
    async (projectId) => {
      let result = await AnnotatorAPI.GetSourceImageSetList(
        projectId,
        _sourceImageSetQueryCondition
      );
      return result.data;
    },
    [_sourceImageSetQueryCondition]
  );

  function handlerSourceImageSet_QueryConditionChange(queryCondition) {
    setSourceImageSetQueryCondition((state) => ({
      ...state,
      ...queryCondition,
    }));
  }

  useEffect(() => {
    async function fctLoad() {
      let result;
      let projectId;

      dispatch(U2ReduxSlice.isBackdropOpen_set(true));

      result = await AnnotatorAPI.GetProject();

      // 프로젝트 관리 추가 후 변경해야 할 로직 (team 에 속한 프로젝트가 없을 경우 자동 생성)
      if (result.data.length === 0) {
        result = await AnnotatorAPI.UpdateProject(-1, {
          projectId: -1,
          projectName: "PBS Morphology Test",
        });
      }

      projectId = result.data[0].projectId;

      // Library Load (Label)
      result = await AnnotatorAPI.GetLabelByProjectId(projectId);
      setLabels(result.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));

      // Library Load (SourceImageSetTag)
      result = await AnnotatorAPI.GetSourceImageSetTag(projectId);
      setSourceImageSetTag(result.data);

      // Data Load (SourceImageSet)
      result = await fctLoadSourceImageSetList(projectId);

      result = Lodash.map(result, (item) => {
        if (item.cellInfo) {
          return { ...item, cellInfo: JSON.parse(item.cellInfo) };
        } else {
          return item;
        }
      });

      setSourceImageSets(result);

      dispatch(
        U2ReduxSlice.breadcrumbs_set([{ label: "PBS Morphology", link: "/" }])
      );

      // dispatch(U2ReduxSlice.breadcrumbs_set({
      //     depth: 0,
      //     link: {label: 'PBS Morphology', link: '/'}
      // }));

      dispatch(U2ReduxSlice.isBackdropOpen_set(false));

      return projectId;
    }

    fctLoad().then((res) => {
      setProjectId(res);
    });
  }, [dispatch, _sourceImageSetQueryCondition, fctLoadSourceImageSetList]);

  /****************************************************************************************************
   *
   * Event Handlers
   *
   ****************************************************************************************************/

  /**
   * 라벨 편집창 실행 버튼 클릭
   */
  function handleLabelEditOpenButton_Click() {
    props.onACLCheck(ACLCODE.라벨_추가_실행);
    setIsLabelEditOpen(true);
  }

  /**
   * 라벨 편집창 닫기 버튼 클릭
   */
  function handleLabelEditCloseButton_Click() {
    setIsLabelEditOpen(false);

    AnnotatorAPI.GetLabelByProjectId(_projectId).then((res) => {
      setLabels(res.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));
    });
  }

  /**
   * 라벨 통계 실행 버튼 클릭
   */
  function handleLabelStatOpenButton_Click() {
    props.onACLCheck(ACLCODE.어노테이션_통계_실행);
    setIsAnnotationStatisticsOpen(true);
  }

  /**
   * 원시이미지셋 리스트 Row 클릭
   */
  function handlerSourceImageSetListRow_Click(projectId, sourceImageSetId) {
    navigate(`/annotator/${projectId}/${sourceImageSetId}`);
  }

  /**
   * 원시이미지셋 리스트 Cell 클릭
   */
  function handlerSourceImageSetListRowCell_Click(e, columnName, data) {
    if (columnName === "sourceImageSetStatus") {
      // 상태변경 다이얼로그 실행
      e.stopPropagation();

      props.onACLCheck(ACLCODE.원시이미지셋_상태변경_배포제외, true, true);

      if (data.sourceImageSetStatus === SOURCESTATUS.RELEASE) {
        props.onACLCheck(
          ACLCODE.원시이미지셋_상태변경_배포,
          true,
          true,
          "배포상태인 원시이미지셋의 상태를 변경할 권한이 없습니다."
        );
      }

      setImageSetStatusEditDialog({
        open: true,
        sourceImageSetId: data.sourceImageSetId,
      });
    } else if (columnName === "sourceImageSetTagId") {
      // 태그변경 다이얼로그 실행
      e.stopPropagation();

      props.onACLCheck(ACLCODE.원시이미지셋_상태변경_배포제외, true, true);

      if (data.sourceImageSetStatus === SOURCESTATUS.RELEASE) {
        props.onACLCheck(
          ACLCODE.원시이미지셋_상태변경_배포,
          true,
          true,
          "배포상태인 원시이미지셋의 태그를 변경할 권한이 없습니다."
        );
      }

      setImageSetTagEditDialog({
        open: true,
        sourceImageSetId: data.sourceImageSetId,
      });
    }
  }

  /**
   * 원시이미지셋 상태 변경 다이얼로그 닫기
   * @param statusCode 상태클릭 시 상태 코드, 다이얼로그 외부 클릭 시 빈 값
   */
  function handlerSourceImageSetStatusEdit_Close(sourceImageSetId, statusCode) {
    if (!!statusCode) {
      let modifiedItem = {
        sourceImageSetId: sourceImageSetId,
        sourceImageSetStatus: statusCode,
      };
      AnnotatorAPI.PatchSourceImageSet(
        _projectId,
        sourceImageSetId,
        modifiedItem
      ).then((res) => {
        setSourceImageSets((state) => {
          return Lodash.map(state, (item) =>
            item.sourceImageSetId === sourceImageSetId ? res.data : item
          );
        });
      });
    }

    setImageSetStatusEditDialog({ open: false, sourceImageSetId: null });
  }

  /**
   * 원시이미지셋 태그 변경 다이얼로그 닫기
   * @param tagId 태그 클릭 시 상태 코드, 다이얼로그 외부 클릭 시 빈 값
   */
  function handlerSourceImageSetTagEdit_Close(sourceImageSetId, tagId) {
    if (!!tagId) {
      let modifiedItem = {
        sourceImageSetId: sourceImageSetId,
        sourceImageSetTagId: tagId,
      };
      AnnotatorAPI.PatchSourceImageSet(
        _projectId,
        sourceImageSetId,
        modifiedItem
      ).then((res) => {
        setSourceImageSets((state) => {
          return Lodash.map(state, (item) =>
            item.sourceImageSetId === sourceImageSetId ? res.data : item
          );
        });
      });
    }

    setImageSetTagEditDialog({ open: false, sourceImageSetId: null });
  }

  /**
   * 원시이미지셋 리스트 편집 모드 전환 버튼 클릭
   */
  function handleSourceImageSetEditModeButton_Click() {
    props.onACLCheck(ACLCODE.원시이미지셋_수정_실행, true, true);

    setIsSourceImageSetListEditable((state) => (state = !state));
  }

  /**
   * 원시이미지셋 항목 삭제 버튼 클릭 --> 삭제 가능한지 확인
   */
  function handlerSourceImageSetDeleteButton_Click(e, sourceImageSetId) {
    e.stopPropagation();

    props.onACLCheck(ACLCODE.원시이미지셋_삭제_실행, true, true);

    AnnotatorAPI.GetSourceImageBySourceImageSetId(sourceImageSetId).then(
      (res) => {
        if (res.data.length > 0) {
          // 이미지가 포함되어 있으면 삭제 불가
          setOpenMessageBox({
            open: true,
            title: "원시이미지셋을 삭제할 수 없습니다.",
            description:
              "선택한 원시이미지셋에 이미지가 포함되어 있습니다. 삭제를 위해 이미지를 먼저 삭제해주세요.",
            okButtonCaption: "확인",
            cancelButtonCaption: "",
            onClose: () => {
              setOpenMessageBox({ open: false });
            },
          });
        } else {
          AnnotatorAPI.GetSourceImageSet(_projectId, sourceImageSetId).then(
            (res) => {
              if (
                res.data.length > 0 &&
                res.data[0].sourceImageSetStatus === SOURCESTATUS.RELEASE
              ) {
                // 상태가 릴리즈이면 삭제 불가
                setOpenMessageBox({
                  open: true,
                  title: "원시이미지셋을 삭제할 수 없습니다.",
                  description:
                    '선택한 원시이미지셋의 상태가 "배포" 입니다. "배포" 된 이미지셋은 삭제할 수 없습니다.',
                  okButtonCaption: "확인",
                  cancelButtonCaption: "",
                  onClose: () => {
                    setOpenMessageBox({ open: false });
                  },
                });
              } else {
                // 삭제 할 것인지 확인 및 OK 시 삭제
                setOpenMessageBox({
                  open: true,
                  title: "원시이미지셋을 삭제하시겠습니까?",
                  description:
                    "선택한 원시이미지셋이 삭제 됩니다. 삭제된 데이터는 복구할 수 없습니다.",
                  okButtonCaption: "삭제",
                  cancelButtonCaption: "취소",
                  onClose: (button) => {
                    if (button === "OK") {
                      fctSourceImageSetDelete(sourceImageSetId);
                    }

                    setOpenMessageBox({ open: false });
                  },
                });
              }
            }
          );
        }
      }
    );
  }

  /**
   * 원시이미지 AI 서버로 업로드
   */
  function handlerSourceImageSetUploadsButton_Click(e, selectedRows) {
    props.onACLCheck(ACLCODE.원시이미지셋_업로드_실행, true, true);

    // console.log(selectedRows);

    fctSourceImageUpload(selectedRows);
  }

  function handlerSourceImageSetUploadButton_Click(e, sourceImageSetId) {
    e.stopPropagation();

    props.onACLCheck(ACLCODE.원시이미지셋_업로드_실행, true, true);

    fctSourceImageUpload([sourceImageSetId]);
  }

  async function fctSourceImageUpload(sourceImageSetIdList) {
    dispatch(U2ReduxSlice.isBackdropOpen_set(true));
    dispatch(U2ReduxSlice.backdropProgressValue_set(0));

    var sourceImageSetId;

    for (var j = 0; j < sourceImageSetIdList.length; j++) {
      sourceImageSetId = sourceImageSetIdList[j];

      await AnnotatorAPI.SendImageToAIServerPrepare(
        _projectId,
        sourceImageSetId
      );

      let images = await AnnotatorAPI.GetSourceImageBySourceImageSetId(
        sourceImageSetId
      );

      for (var i = 0; i < images.data.length; i++) {
        let imageId = images.data[i].imageId;
        let image = images.data[i].image;
        let status = images.data[i].status;

        if (status === SOURCESTATUS.VALID) {
          let annotations = await AnnotatorAPI.GetAnnotation(1, imageId);

          await AnnotatorAPI.SendImageToAIServer(
            _projectId,
            sourceImageSetId,
            imageId,
            image,
            annotations.data
          );

          dispatch(
            U2ReduxSlice.backdropProgressValue_set(
              (j * 100 + ((i + 1) * 100) / images.data.length) /
                sourceImageSetIdList.length
            )
          );
        }
      }
    }

    dispatch(U2ReduxSlice.isBackdropOpen_set(false));
    dispatch(U2ReduxSlice.backdropProgressValue_set(""));
  }

  function handlerSourceImageSetIsRareUploadButton_Click(e) {
    e.stopPropagation();

    props.onACLCheck(ACLCODE.원시이미지셋_업로드_실행, true, true);

    fctSourceImageUpload_isRare(true);
  }

  function handlerSourceImageSetNonIsRareUploadButton_Click(e, selectedRows) {
    e.stopPropagation();

    props.onACLCheck(ACLCODE.원시이미지셋_업로드_실행, true, true);

    fctSourceImageUpload_isRare(false, selectedRows);
  }
  

  async function fctSourceImageUpload_isRare(type, sourceImageSetIdList = []) {
    dispatch(U2ReduxSlice.isBackdropOpen_set(true));
    dispatch(U2ReduxSlice.backdropProgressValue_set(0));

    var sourceImageSetId;
    if(type){
      sourceImageSetId = "isRare";
      
      await AnnotatorAPI.SendImageToAIServerPrepare(
        _projectId,
        sourceImageSetId
      );
      let images = await AnnotatorAPI.GetSourceImageByisRare(_projectId);
  
      for (var i = 0; i < images.data.length; i++) {
        let imageId = images.data[i].imageId;
        let image = images.data[i].image;
        let status = images.data[i].status;
  
        if (status === SOURCESTATUS.VALID) {
          let annotations = await AnnotatorAPI.GetAnnotation(1, imageId);
  
          await AnnotatorAPI.SendImageToAIServer(
            _projectId,
            sourceImageSetId,
            imageId,
            image,
            annotations.data
          );
  
          dispatch(
            U2ReduxSlice.backdropProgressValue_set(((i + 1) * 100) / images.data.length));
        }
      }
    } else {
      var sourceImageSetName = "nonIsRare";
      await AnnotatorAPI.SendImageToAIServerPrepare(
        _projectId,
        sourceImageSetName
      );

      for (var j = 0; j < sourceImageSetIdList.length; j++) {
        sourceImageSetId = sourceImageSetIdList[j];


        let images = await AnnotatorAPI.GetSourceImageBySourceImageSetId(
          sourceImageSetId,
          false
        );

        for (var k = 0; k < images.data.length; k++) {
          let imageId = images.data[k].imageId;
          let image = images.data[k].image;
          let status = images.data[k].status;

          if (status === SOURCESTATUS.VALID) {
            let annotations = await AnnotatorAPI.GetAnnotation(1, imageId);

            await AnnotatorAPI.SendImageToAIServer(
              _projectId,
              sourceImageSetName,
              imageId,
              image,
              annotations.data
            );

            dispatch(
              U2ReduxSlice.backdropProgressValue_set(
                (j * 100 + ((k + 1) * 100) / images.data.length) /
                  sourceImageSetIdList.length
              )
            );
          }
        }
      }
    }

    dispatch(U2ReduxSlice.isBackdropOpen_set(false));
    dispatch(U2ReduxSlice.backdropProgressValue_set(""));
    
  }
  

  /**
   * 분석
   */
  // const fctAnalysisByImageId = useCallback((memberId, modelName, sourceImageSetId, imageId, labels) => {
  //     return new Promise((ok, no) => {
  //         AnnotatorAPI.GetSourceImage(sourceImageSetId, imageId).then((res1) => {
  //             AnnotatorAPI.GetIntelliAnnotation(memberId, modelName, imageId, res1.data[0].width, res1.data[0].height, res1.data[0].image).then((res2) => {
  //                 if (res2.data.result_code === 'OK') {

  //                     let entities = Lodash.map(res2.data.result, (inf) => {
  //                         let label = Lodash.find(labels, (label) => label.label === inf.class);

  //                         // console.log(labels);

  //                         // console.log({
  //                         //     infClass: inf.class,
  //                         //     foundLabelId: label.labelId,
  //                         //     foundLabel: label.label
  //                         // });

  //                         if (!!label) {
  //                             return {
  //                                 imageId: imageId,
  //                                 labelId: label.labelId,
  //                                 isIntelli: true,
  //                                 isManual: false,
  //                                 label: label.label,
  //                                 prob: Math.trunc(inf.prob * 100),
  //                                 color: label.color,
  //                                 boundingBox: DrawUtil.fctCalcRectangleCoordinate(Number(inf.x1), Number(inf.y1), inf.x2, inf.y2)
  //                             };
  //                         } else {
  //                             return {
  //                                 imageId: imageId,
  //                                 labelId: -1,
  //                                 label: inf.class,
  //                                 isIntelli: true,
  //                                 isManual: false,
  //                                 prob: Math.trunc(inf.prob * 100),
  //                                 color: '#FFFF00',
  //                                 boundingBox: DrawUtil.fctCalcRectangleCoordinate(Number(inf.x1), Number(inf.y1), inf.x2, inf.y2)
  //                             };
  //                         }
  //                     });

  //                     return ok(entities);

  //                     // setAnnotations([..._annotations, ...entities.filter((item) => !!item)]);

  //                 } else {
  //                     return no();
  //                     // dispatch(U2ReduxSlice.snackbar_open('인퍼런스 중 오류가 발생하였습니다'));
  //                 }
  //             })
  //         });
  //     });
  // }, []);


  /**
   * 원시이미지 셋 목록의 체크박스 선택 시 선택된 행의 ID 를 저장
   * @param {*} selectedRows
   */
  function handlerSourceImageSet_SelectionModelChange(selectedRows) {
    setSelectedRows(selectedRows);
  }

  /**
   * 원시이미지셋 항목 추가 버튼 클릭
   */
  function handleSourceImageSetAddButton_Click() {
    props.onACLCheck(ACLCODE.원시이미지셋_추가_실행, true, true);
    setSourceImageSetNameEditValue("");
    setImageSetNameEditDialog({
      open: true,
      sourceImageSetId: -1,
      isEditMode: false,
    });
  }

  /**
   * 원시이미지셋 항목 수정 버튼 클릭
   */
  function handlerSourceImageSetEditButton_Click(e, sourceImageSetId) {
    e.stopPropagation();

    props.onACLCheck(ACLCODE.원시이미지셋_수정_실행, true, true);

    AnnotatorAPI.GetSourceImageSet(_projectId, sourceImageSetId).then((res) => {
      if (
        res.data.length > 0 &&
        res.data[0].sourceImageSetStatus === SOURCESTATUS.RELEASE
      ) {
        // 상태가 릴리즈이면 수정 불가
        setOpenMessageBox({
          open: true,
          title: "원시이미지셋을 수정할 수 없습니다.",
          description:
            '선택한 원시이미지셋의 상태가 "배포" 입니다. "배포" 된 이미지셋은 수정할 수 없습니다.',
          okButtonCaption: "확인",
          cancelButtonCaption: "",
          onClose: () => {
            setOpenMessageBox({ open: false });
          },
        });
      } else {
        let selectedSourceImageSet = _sourceImageSets.find(
          (item) => item.sourceImageSetId === sourceImageSetId
        );

        setSourceImageSetNameEditValue(
          selectedSourceImageSet.sourceImageSetName
        );
        setImageSetNameEditDialog({
          open: true,
          sourceImageSetId: sourceImageSetId,
          isEditMode: true,
        });
      }
    });
  }

  /**
   * 원시이미지셋 이름 편집 텍스트필드 KeyDown
   */
  function handleSourceImageSetNameEdit_KeyDown(
    e,
    isEditMode,
    sourceImageSetId,
    sourceImageSetName
  ) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSourceImageSetNameEdit_Close(
        "OK",
        isEditMode,
        sourceImageSetId,
        sourceImageSetName
      );
    }
  }

  /**
   * 원시이미지셋 항목 수정 다이얼로그 닫기 버튼 클릭
   */
  function handleSourceImageSetNameEdit_Close(
    button,
    isEditMode,
    sourceImageSetId,
    sourceImageSetName
  ) {
    if (button === "OK") {
      if (isEditMode) {
        // 편집
        let entity = new SourceImageSetEntity(
          {
            projectId: _projectId,
            sourceImageSetId: sourceImageSetId,
            sourceImageSetName: sourceImageSetName,
          },
          _sourceImageSets
        );

        fctSourceImageSetUpdate(entity, setSourceImageSets);
      } else {
        // 추가
        let entity = new SourceImageSetEntity({
          projectId: _projectId,
          sourceImageSetName: sourceImageSetName,
        });

        fctSourceImageSetAdd(entity, setSourceImageSets);
      }
    }

    setImageSetNameEditDialog({
      open: false,
      sourceImageSetId: null,
      isEditMode: false,
    });
  }

  function handleSourceImageSetNameEdit_Changed(e) {
    setSourceImageSetNameEditValue(e.target.value);
  }

  /**
   * 원시이미지셋 데이터 추가
   */
  function fctSourceImageSetAdd(entity, setStateFunction) {
    if (!!entity.value) {
      AnnotatorAPI.UpdateSourceImageSet(_projectId, -1, entity.value).then(
        (res) => {
          if (res.status === 200) {
            setStateFunction((state) => {
              return [res.data, ...state];
            });
          }
        }
      );
    }
  }

  /**
   * 원시이미지셋 데이터 수정
   */
  function fctSourceImageSetUpdate(entity, setStateFunction) {
    if (!!entity.keyColumn && !!entity.keyValue) {
      AnnotatorAPI.UpdateSourceImageSet(
        _projectId,
        entity.keyValue,
        entity.value
      ).then((res) => {
        if (res.status === 200) {
          setStateFunction((state) => {
            return Lodash.map(state, (item) =>
              item[entity.keyColumn] === entity.keyValue ? res.data : item
            );
          });
        }
      });
    }
  }

  /**
   * 원시이미미셋 데이터 삭제
   */
  function fctSourceImageSetDelete(sourceImageSetId) {
    //  if (!!keyProperty && !!keyValue) {
    //     Lodash.remove(array, (item) => item[keyProperty] === keyValue);
    //     setStateFunction(array);
    //     AnnotatorAPI.DeleteSourceImageSet(_projectId, keyValue);
    // }

    setSourceImageSets((state) => {
      return Lodash.filter(state, (item) => {
        return item.sourceImageSetId !== sourceImageSetId;
      });
    });

    AnnotatorAPI.DeleteSourceImageSet(_projectId, sourceImageSetId);
  }

  function handlerModelReview_Click() {
    navigate(`/annotator/10000/10000`);
  }

  return (
    <>
      <U2RoundedBox sx={{ flexBasis: "112px", marginTop: "2px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{ fontWeight: "bold", fontSize: "large", marginTop: "2px" }}
            >
              라벨
            </Box>
            <U2AuthComponent aclId={ACLCODE.라벨_추가_실행}>
              <Tooltip title="라벨 편집">
                <IconButton
                  size="small"
                  sx={{ marginLeft: "6px" }}
                  onClick={handleLabelEditOpenButton_Click}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.어노테이션_통계_실행}>
              <Tooltip title="라벨링 현황">
                <IconButton
                  size="small"
                  onClick={handleLabelStatOpenButton_Click}
                >
                  <InsightsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
          </Box>
          <U2ImageSetManagerLabelList
            labels={_labels}
            showLabelDeleteButton={false}
            labelSelectEnabled={false}
          />
        </Box>
      </U2RoundedBox>
      <U2RoundedBox sx={{ flexGrow: 1, marginTop: "0px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{ fontWeight: "bold", fontSize: "large", marginTop: "2px" }}
            >
              원시이미지셋 목록
            </Box>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_수정_실행}>
              <Tooltip title="원시이미지셋 편집 버튼 보기">
                <IconButton
                  size="small"
                  sx={{ marginLeft: "6px" }}
                  onClick={handleSourceImageSetEditModeButton_Click}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_추가_실행}>
              <Tooltip title="원시이미지셋 추가">
                <IconButton
                  size="small"
                  onClick={handleSourceImageSetAddButton_Click}
                >
                  <AddCircleOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
              <Tooltip title="원시이미지셋 배포(all)">
                <IconButton
                  size="small"
                  onClick={(e) =>
                    handlerSourceImageSetUploadsButton_Click(e, _selectedRows)
                  }
                >
                  <CloudUploadOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
              <Tooltip title="원시이미지셋 배포(seleced)">
                <IconButton
                  size="small"
                  onClick={(e) =>
                    handlerSourceImageSetIsRareUploadButton_Click(e)
                  }
                >
                  <CloudUploadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
              <Tooltip title="원시이미지셋 배포(non-seleced)">
                <IconButton
                  size="small"
                  onClick={(e) =>
                    handlerSourceImageSetNonIsRareUploadButton_Click(e, _selectedRows)
                  }
                >
                  <CloudUploadTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
            <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
              <Tooltip title="모델 검토">
                <IconButton
                  size="small"
                  onClick={(e) => handlerModelReview_Click(e, _selectedRows)}
                >
                  <ReviewsOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </U2AuthComponent>
          </Box>
          <U2ImageSetManagerSourceImageSetList
            sourceImageSets={_sourceImageSets}
            isEditable={_isSourceImageSetListEditable}
            queryCondition={_sourceImageSetQueryCondition}
            tag={_sourceImageSetTag}
            onRow_Click={handlerSourceImageSetListRow_Click}
            onRowCell_Click={handlerSourceImageSetListRowCell_Click}
            onEditButton_Click={handlerSourceImageSetEditButton_Click}
            onDeleteButton_Click={handlerSourceImageSetDeleteButton_Click}
            onUploadButton_Click={handlerSourceImageSetUploadButton_Click}
            onDataGrid_SelectionModelChange={
              handlerSourceImageSet_SelectionModelChange
            }
            onDataGrid_QueryConditionChange={
              handlerSourceImageSet_QueryConditionChange
            }
            pageSize={_sourceImageSetQueryCondition.Page.pageSize}
            onACLCheck={props.onACLCheck}
          />
        </Box>
      </U2RoundedBox>
      <U2ImageSetManagerLabelEditDialog
        labels={_labels}
        projectId={_projectId}
        open={_IslabelEditOpen}
        onClose={handleLabelEditCloseButton_Click}
      />
      <U2MessageBox
        open={_openMessageBox.open}
        title={_openMessageBox.title}
        description={_openMessageBox.description}
        okButtonCaption={_openMessageBox.okButtonCaption}
        cancelButtonCaption={_openMessageBox.cancelButtonCaption}
        onClose={_openMessageBox.onClose}
      />
      <U2ImageSetNameEditDialog
        open={_imageSetNameEditDialog.open}
        isEditMode={_imageSetNameEditDialog.isEditMode}
        value={_sourceImageSetNameEditValue}
        onClose={(button) =>
          handleSourceImageSetNameEdit_Close(
            button,
            _imageSetNameEditDialog.isEditMode,
            _imageSetNameEditDialog.sourceImageSetId,
            _sourceImageSetNameEditValue
          )
        }
        onKeyDown={(e) =>
          handleSourceImageSetNameEdit_KeyDown(
            e,
            _imageSetNameEditDialog.isEditMode,
            _imageSetNameEditDialog.sourceImageSetId,
            _sourceImageSetNameEditValue
          )
        }
        onChange={handleSourceImageSetNameEdit_Changed}
      />
      <U2ImageSetStatusEditDialog
        open={_imageSetStatusEditDialog.open}
        onACLCheck={props.onACLCheck}
        onClose={(statusCode) =>
          handlerSourceImageSetStatusEdit_Close(
            _imageSetStatusEditDialog.sourceImageSetId,
            statusCode
          )
        }
      />
      <U2ImageSetTagEditDialog
        open={_imageSetTagEditDialog.open}
        tag={_sourceImageSetTag}
        onACLCheck={props.onACLCheck}
        onClose={(tagId) =>
          handlerSourceImageSetTagEdit_Close(
            _imageSetTagEditDialog.sourceImageSetId,
            tagId
          )
        }
      />
      {/* 라벨링 현황 다이얼로그 */}
      {_isAnnotationStatisticsOpen && (
        <U2AnnotationStatisticsDialog
          open={_isAnnotationStatisticsOpen}
          tag={_sourceImageSetTag}
          projectId={_projectId}
          onClose={() => setIsAnnotationStatisticsOpen(false)}
        />
      )}
    </>
  );
}

export default ImageSetManager;

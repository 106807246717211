import Axios from "../Utils/AxiosUtil";

var StatisticsAPI =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.StatisticsAPI = StatisticsAPI;
}

const SERVICE = "/api/statistics";

StatisticsAPI.GetLabelWithAnnotationCount =
  function GetLabelWithAnnotationCount(showOnlyMyAnnotation, showOnlyIsRare, tagId, projectId) {
    let option = showOnlyMyAnnotation ? "mine" : showOnlyIsRare ? "rare" : "all";
    option = option + ";" + tagId;
    return Axios.Get(
      SERVICE,
      `/LabelWithAnnotationCount?projectId=${projectId}&option=${option}`
    );
  };

StatisticsAPI.GetSourceImageWithAnnotationCountByLabelId =
  function GetSourceImageWithAnnotationCountByLabelId(
    projectId,
    labelId,
    showOnlyMyAnnotation,
    showOnlyIsRare,
    tagId
  ) {
    let option = showOnlyMyAnnotation ? "mine" : showOnlyIsRare ? "rare" : "all";
    option = option + ";" + tagId;
    return Axios.Get(
      SERVICE,
      `/SourceImageWithAnnotationCountByLabelId/${projectId}/${labelId}/${option}`
    );
  };

StatisticsAPI.GetAnnotationBySourceImageIdAndLabelId =
  function GetAnnotationBySourceImageIdAndLabelId(imageId, labelId) {
    return Axios.Get(
      SERVICE,
      `/AnnotationBySourceImageIdAndLabelId/${imageId}/${labelId}`
    );
  };

StatisticsAPI.GetAnnotationAndSourceImageByLabelIdAndPaging =
  function GetAnnotationAndSourceImageByLabelIdAndPaging(
    labelId,
    showOnlyMyAnnotation,
    showOnlyIsRare,
    tagId,
    limit,
    page
  ) {
    let offset = limit * (page - 1);
    let option = showOnlyMyAnnotation ? "mine" : showOnlyIsRare ? "rare" : "all";
    option = option + ";" + tagId;
    return Axios.Get(
      SERVICE,
      `/AnnotationAndSourceImageByLabelIdAndPaging/${labelId}/${option}/${limit}/${offset}`
    );
  };
StatisticsAPI.GetAnnotationAndSourceImageCountByLabelIdAndPaging =
  function GetAnnotationAndSourceImageCountByLabelIdAndPaging(
    labelId,
    showOnlyMyAnnotation,
    showOnlyIsRare,
    tagId
  ) {
    let option = showOnlyMyAnnotation ? "mine" : showOnlyIsRare ? "rare" : "all";
    option = option + ";" + tagId;
    return Axios.Get(
      SERVICE,
      `/AnnotationAndSourceImageCountByLabelIdAndPaging/${labelId}/${option}`
    );
  };

export default StatisticsAPI;

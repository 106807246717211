import Axios from "../Utils/AxiosUtil";

var AnnotatorAPI =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.AnnotatorAPI = AnnotatorAPI;
}

const SERVICE = "/api/annotator";

AnnotatorAPI.GetProject = function GetProject() {
  return Axios.Get(SERVICE, `/Project`);
};

AnnotatorAPI.UpdateProject = function GetProject(projectId, project) {
  return Axios.Put(SERVICE, `/Project/${projectId}`, project);
};

AnnotatorAPI.GetLabelByProjectId = function GetLabelByProjectId(projectId) {
  return Axios.Get(SERVICE, `/Project/${projectId}/Label`);
};

AnnotatorAPI.GetLabel = function GetLabel(projectId, labelId) {
  return Axios.Get(SERVICE, `/Project/${projectId}/Label/${labelId}`);
};

AnnotatorAPI.UpdateLabel = function UpdateLabel(projectId, labelId, label) {
  return Axios.Put(SERVICE, `/Project/${projectId}/Label/${labelId}`, label);
};

AnnotatorAPI.DeleteLabel = function DeleteLabel(projectId, labelId) {
  return Axios.Delete(SERVICE, `/Project/${projectId}/Label/${labelId}`);
};

AnnotatorAPI.GetSourceImageSetTag = function GetSourceImageSetTag(projectId) {
  return Axios.Get(SERVICE, `/Project/${projectId}/SourceImageSetTag`);
};

AnnotatorAPI.GetSourceImageSetList = function GetSourceImageSetList(
  projectId,
  query
) {
  // return Axios.Post(SERVICE, `/Project/${projectId}/SourceImageSet`, query);
  return Axios.Get(SERVICE, `/Project/${projectId}/SourceImageSet`, query);
};

AnnotatorAPI.GetSourceImageSet = function GetSourceImageSet(
  projectId,
  sourceImageSetId
) {
  return Axios.Get(
    SERVICE,
    `/Project/${projectId}/SourceImageSet/${sourceImageSetId}`
  );
};

AnnotatorAPI.UpdateSourceImageSet = function UpdateSourceImageSet(
  projectId,
  sourceImageSetId,
  sourceImageSet
) {
  return Axios.Put(
    SERVICE,
    `/Project/${projectId}/SourceImageSet/${sourceImageSetId}`,
    sourceImageSet
  );
};

AnnotatorAPI.PatchSourceImageSet = function PatchSourceImageSet(
  projectId,
  sourceImageSetId,
  sourceImageSet
) {
  return Axios.Patch(
    SERVICE,
    `/Project/${projectId}/SourceImageSet/${sourceImageSetId}`,
    sourceImageSet
  );
};

AnnotatorAPI.UpdateSourceImageSetValLastDate =
  function UpdateSourceImageSetValLastDate(projectId, sourceImageSetId) {
    return Axios.Patch(
      SERVICE,
      `/Project/${projectId}/SourceImageSet/${sourceImageSetId}/setValLastDate`
    );
  };
// AnnotatorAPI.UpdateSourceImageSetStatus = function UpdateSourceImageSetStatus(projectId, sourceImageSetId, sourceImageSetStatus) {
// 	return Axios.Put(SERVICE, `/Project/${projectId}/SourceImageSet/${sourceImageSetId}/sourceImageSetStatus`, {sourceImageSetId: sourceImageSetId, sourceImageSetStatus: sourceImageSetStatus});
// }

AnnotatorAPI.DeleteSourceImageSet = function DeleteSourceImageSet(
  projectId,
  sourceImageSetId
) {
  return Axios.Delete(
    SERVICE,
    `/Project/${projectId}/SourceImageSet/${sourceImageSetId}`
  );
};

AnnotatorAPI.GetSourceImageBySourceImageSetId =
  function GetSourceImageBySourceImageSetId(sourceImageSetId, isRare=null) {
    return Axios.Get(
      SERVICE,
      `/SourceImageSet/${sourceImageSetId}/SourceImage?isRare=${isRare}`
    );
  };

AnnotatorAPI.GetSourceImageByisRare =
function GetSourceImageByisRare() {
  return Axios.Get(
    SERVICE,
    `/SourceImage/isRare`
  );
};
  

AnnotatorAPI.GetSourceImage = function GetSourceImage(
  sourceImageSetId,
  sourceImageId
) {
  return Axios.Get(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}`
  );
};

AnnotatorAPI.UpdateSourceImage = function UpdateSourceImage(
  sourceImageSetId,
  sourceImageId,
  sourceImage
) {
  return Axios.Put(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}`,
    sourceImage
  );
};

AnnotatorAPI.UpdateSourceImageisRare = function UpdateSourceImageisRare(
  sourceImageSetId,
  sourceImageId,
  annotationId,
  isRare
) {
  return Axios.Patch(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}`,
    {
      isRare: isRare,
      annotationId: annotationId,
    }
  );
};

// AnnotatorAPI.UpdateSourceImageStatus = function UpdateSourceImageStatus(sourceImageSetId, sourceImageId, status) {
// 	return Axios.Put(SERVICE, `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}/status`, {imageId: sourceImageId, status: status});
// }

AnnotatorAPI.DeleteSourceImage = function DeleteSourceImage(
  sourceImageSetId,
  sourceImageId
) {
  return Axios.Delete(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}`
  );
};

AnnotatorAPI.GetAnnotation = function GetAnnotation(
  sourceImageSetId,
  sourceImageId
) {
  return Axios.Get(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}/Annotation`
  );
};

AnnotatorAPI.UpdateAnnotation = function UpdateAnnotation(
  sourceImageSetId,
  sourceImageId,
  annotationId,
  annotations
) {
  return Axios.Put(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}/Annotation/${annotationId}`,
    annotations
  );
};

AnnotatorAPI.DeleteAnnotation = function DeleteAnnotation(
  sourceImageSetId,
  sourceImageId,
  annotationId
) {
  return Axios.Delete(
    SERVICE,
    `/SourceImageSet/${sourceImageSetId}/SourceImage/${sourceImageId}/Annotation/${annotationId}`
  );
};

AnnotatorAPI.GetIntelliAnnotation = function GetIntelliAnnotation(
  memberId,
  modelName,
  imageId,
  width,
  height,
  image
) {
  // 클라이언트에서 AI 서버에 접속
  // return Axios.Post(SERVICE_AI, '/inf', {memberId:memberId, imageId:imageId, width:width, height:height, image:image})
  // 서버에서 AI 서버에 접속
  return Axios.Post(SERVICE, `/inf`, {
    memberId: memberId,
    model_name: modelName,
    imageId: imageId,
    width: width,
    height: height,
    image: image,
  });
};

AnnotatorAPI.GetIntelliModels = function GetIntelliModels() {
  return Axios.Get(SERVICE, `/get_models`);
};

AnnotatorAPI.GetIntelliBoundingBox = function GetIntelliBoundingBox(
  memberId,
  image
) {
  return Axios.Post(SERVICE, `/find_boundingbox`, {
    memberId: memberId,
    image: image,
  });
};

AnnotatorAPI.SendImageToAIServerPrepare = function SendImageToAIServerPrepare(
  projectId,
  sourceImageSetId
) {
  // return Axios.Post(SERVICE_AI, `/uploadprepare`, {projectId: projectId, sourceImageSetId:sourceImageSetId});
  return Axios.Post(SERVICE, `/uploadprepare`, {
    projectId: projectId,
    sourceImageSetId: sourceImageSetId,
  });
};

AnnotatorAPI.SendImageToAIServer = function SendImageToAIServer(
  projectId,
  sourceImageSetId,
  imageId,
  image,
  annotations
) {
  // return Axios.Post(SERVICE_AI, `/upload`, {projectId: projectId, sourceImageSetId:sourceImageSetId, imageId:imageId, image:image, annotations:annotations});
  return Axios.Post(SERVICE, `/upload`, {
    projectId: projectId,
    sourceImageSetId: sourceImageSetId,
    imageId: imageId,
    image: image,
    annotations: annotations,
  });
};

AnnotatorAPI.Test = function Test(p) {
  // return Axios.Post(SERVICE_AI, `/upload`, {projectId: projectId, sourceImageSetId:sourceImageSetId, imageId:imageId, image:image, annotations:annotations});
  return Axios.Post(SERVICE, `/test`, { test: "u2check" });
};


export default AnnotatorAPI;

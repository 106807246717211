import { DataGrid } from "@mui/x-data-grid";

function U2AnnotationStatisticsList(props) {
  const columns = [
    // { field: 'labelId', headerName: '#', width: 50, align: 'center' },
    { field: "category", headerName: "분류", width: 80 },
    { field: "label", headerName: "라벨", width: 140 },
    // { field: 'imageCount', headerName: '이미지', width: 100, align: 'right', headerAlign: 'right' },
    {
      field: "annotationCount",
      headerName: "어노테이션",
      width: 100,
      align: "right",
      headerAlign: "right",
    },
  ];

  function handlerGetRowId(row) {
    return row.labelId;
  }

  function handlerRowClick(gridRowParams) {
    props.onRow_Click(gridRowParams.id, gridRowParams.row.label);
  }

  return (
    <DataGrid
      rows={props.annotationStatistics}
      columns={columns}
      pageSize={1}
      rowsPerPageOptions={[5]}
      disableRowSelectionOnClick
      getRowId={handlerGetRowId}
      onRowClick={handlerRowClick}
    />
  );
}

export default U2AnnotationStatisticsList;
